[class*="f-icon"] {
    vertical-align: top;
    display: inline-block;
}

.f-icon-size-md svg {
    .size(24px);
}

.f-icon-size-sm svg {
    .size(18px);
}

.f-icon-size-xs svg {
    .size(16px);
}