.ma-0 {
    margin: 0px !important;
}

.mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.pa-0 {
    padding: 0px !important;
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.ma-1 {
    margin: 4px !important;
}

.mx-1 {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.my-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.ml-1 {
    margin-left: 4px !important;
}

.mr-1 {
    margin-right: 4px !important;
}

.mb-1 {
    margin-bottom: 4px !important;
}

.mt-1 {
    margin-top: 4px !important;
}

.pa-1 {
    padding: 4px !important;
}

.px-1 {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.py-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.pl-1 {
    padding-left: 4px !important;
}

.pr-1 {
    padding-right: 4px !important;
}

.pb-1 {
    padding-bottom: 4px !important;
}

.pt-1 {
    padding-top: 4px !important;
}

.ma-2 {
    margin: 8px !important;
}

.mx-2 {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.my-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.ml-2 {
    margin-left: 8px !important;
}

.mr-2 {
    margin-right: 8px !important;
}

.mb-2 {
    margin-bottom: 8px !important;
}

.mt-2 {
    margin-top: 8px !important;
}

.pa-2 {
    padding: 8px !important;
}

.px-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.pl-2 {
    padding-left: 8px !important;
}

.pr-2 {
    padding-right: 8px !important;
}

.pb-2 {
    padding-bottom: 8px !important;
}

.pt-2 {
    padding-top: 8px !important;
}

.ma-3 {
    margin: 16px !important;
}

.mx-3 {
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.my-3 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.ml-3 {
    margin-left: 16px !important;
}

.mr-3 {
    margin-right: 16px !important;
}

.mb-3 {
    margin-bottom: 16px !important;
}

.mt-3 {
    margin-top: 16px !important;
}

.pa-3 {
    padding: 16px !important;
}

.px-3 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.py-3 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.pl-3 {
    padding-left: 16px !important;
}

.pr-3 {
    padding-right: 16px !important;
}

.pb-3 {
    padding-bottom: 16px !important;
}

.pt-3 {
    padding-top: 16px !important;
}

.ma-4 {
    margin: 24px !important;
}

.mx-4 {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.my-4 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.ml-4 {
    margin-left: 24px !important;
}

.mr-4 {
    margin-right: 24px !important;
}

.mb-4 {
    margin-bottom: 24px !important;
}

.mt-4 {
    margin-top: 24px !important;
}

.pa-4 {
    padding: 24px !important;
}

.px-4 {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.py-4 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
}

.pl-4 {
    padding-left: 24px !important;
}

.pr-4 {
    padding-right: 24px !important;
}

.pb-4 {
    padding-bottom: 24px !important;
}

.pt-4 {
    padding-top: 24px !important;
}

.ma-5 {
    margin: 48px !important;
}

.mx-5 {
    margin-left: 48px !important;
    margin-right: 48px !important;
}

.my-5 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
}

.ml-5 {
    margin-left: 48px !important;
}

.mr-5 {
    margin-right: 48px !important;
}

.mb-5 {
    margin-bottom: 48px !important;
}

.mt-5 {
    margin-top: 48px !important;
}

.pa-5 {
    padding: 48px !important;
}

.px-5 {
    padding-left: 48px !important;
    padding-right: 48px !important;
}

.py-5 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
}

.pl-5 {
    padding-left: 48px !important;
}

.pr-5 {
    padding-right: 48px !important;
}

.pb-5 {
    padding-bottom: 48px !important;
}

.pt-5 {
    padding-top: 48px !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.f-word-break-break-word {
    word-break: break-word;
}

.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.f-truncate-100 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px !important;
}

// Usage:
// <div class="f-truncate-two-line"></div>
// Truncate the text into two lines
.f-truncate-two-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.shrink {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
}

// Usage:
// <div class="overflow-hidden"></div>
.overflow-hidden {
    overflow: hidden;
}


// Border for all side of container.
// Usage:
// <div class="f-border-all"></div>
.f-border-all {
    // default color
    border: 1px solid #EDF0F0 !important;

    &[class*='f-color-'] {
        border-color: currentColor !important;
    }
}

// Common responsive helper classes
////////////////////////////////////

// Hiiden XS
// Usage:
// <div class="f-hidden-xs"></div>
// Hides the element on extra small devices
.f-hidden-xs {
    @media (max-width: @screen-sm) {
        display: none !important;
    }
}

// Hiiden MD
// Usage:
// <div class="f-hidden-md"></div>
// Hides the element on medium devices
.f-hidden-md {
    @media (min-width: @screen-md) {
        display: none !important;
    }
}

// Layout wrap on XS
// Usage:
// <div class="f-layout-wrap-xs"></div>
// Changes the flex wrap to wrap on extra small devices
.f-layout-wrap-xs {
    @media (max-width: @screen-lg) {
        flex-wrap: wrap !important;
    }
}

// Helper class for flex direction column
// Usage:
// <div class="f-flex-column"></div> ..
// Changes the direction to column on extra small devices
.f-flex-column-xs {
    @media (max-width: @screen-lg) {
        flex-direction: column !important;
    }
}

// Helper class to set width {100} on XS
// Usage:
// <div class="f-width-100-xs"></div> ..
// Changes the width on extra small devices
.f-width-100-xs {
    @media (max-width: @screen-lg) {
        width: 100% !important;
    }
}

// Helper class to set height auto on XS
// Usage:
// <div class="f-height-auto-xs"></div> ..
// Changes the height on extra small devices
.f-height-auto-xs {
    @media (max-width: @screen-lg) {
        height: auto !important;
        min-height: 0 !important;
        max-height: none !important;
    }
}

// Justify start on XS
// Usage:
// <div class="f-justify-start-xs"></div>
// Changes the justify content to start on extra small devices
.f-justify-start-xs {
    @media (max-width: @screen-lg) {
        justify-content: flex-start !important;
    }
}

// Justify center on XS
// Usage:
// <div class="f-justify-center-xs"></div>
// Changes the justify content to center on extra small devices
.f-justify-center-xs {
    @media (max-width: @screen-lg) {
        justify-content: center !important;
    }
}

// Justify Space Between on XS
// Usage:
// <div class="f-justify-between-xs"></div>
// Changes the justify content to center on extra small devices
.f-justify-between-xs {
    @media (max-width: @screen-lg) {
        justify-content: space-between !important;
    }
}

// Justify end on XS
// Usage:
// <div class="f-justify-end-xs"></div>
// Changes the justify content to end on extra small devices
.f-justify-end-xs {
    @media (max-width: @screen-lg) {
        justify-content: flex-end !important;
    }
}

// Align start on XS
// Usage:
// <div class="f-align-start-xs"></div>
// Changes the align items to start on extra small devices
.f-align-start-xs {
    @media (max-width: @screen-lg) {
        align-items: flex-start !important;
    }
}

// Align center on XS
// Usage:
// <div class="f-align-center-xs"></div>
// Changes the align items to center on extra small devices
.f-align-center-xs {
    @media (max-width: @screen-lg) {
        align-items: center !important;
    }
}

// Align end on XS
// Usage:
// <div class="f-align-end-xs"></div>
// Changes the align items to end on extra small devices
.f-align-end-xs {
    @media (max-width: @screen-lg) {
        align-items: flex-end !important;
    }
}

// Padding and Margin classes
// Usage:
// <div class="f-px-1-xs"></div>
// <div class="f-py-1-xs"></div>
// <div class="f-mx-1-xs"></div>
// <div class="f-my-1-xs"></div>
// Changes the padding on extra small devices
each(range(4px, 24px, 4), {
    .f-pa-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-top: @value !important;
            padding-right: @value !important;
            padding-bottom: @value !important;
            padding-left: @value !important;
        }
    }
    .f-px-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-left: @value !important;
            padding-right: @value !important;
        }
    }
    .f-py-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-top: @value !important;
            padding-bottom: @value !important;
        }
    }
    .f-pt-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-top: @value !important;
        }
    }
    .f-pb-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-bottom: @value !important;
        }
    }
    .f-pl-@{index}-xs {
        @media (max-width: @screen-lg) {
            padding-left: @value !important;
        }
    }
    .f-pr-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-right: @value !important;
        }
    }
    // Margin classes
    .f-mx-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-left: @value !important;
            margin-right: @value !important;
        }
    }
    .f-my-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-top: @value !important;
            margin-bottom: @value !important;
        }
    }
    .f-mt-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-top: @value !important;
        }
    }
    .f-mb-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-bottom: @value !important;
        }
    }
    .f-ml-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-left: @value !important;
        }
    }
    .f-mr-@{index}-xs {
        @media (max-width: @screen-lg) {
            margin-right: @value !important;
        }
    }
});

// Border Radius
// For Loop to get 4, 8, 12, 16, 20, 24 value for rounded corners
// USAGE: .f-rounded-corner-1 ... 6
// @rounded-class: f-rounded-corner-;
each(range(4px, 24px, 4), {
    .f-rounded-corner-@{index} {
        border-radius: @value !important;
    }
});