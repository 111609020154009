.f-color-dark-black-s30 {
	fill: currentColor;
	color: #BCBDBF !important;
}

.f-color-dark-black-s50 {
	fill: currentColor;
	color: #8F9094 !important;
}

.f-color-dark-black-s60 {
	fill: currentColor;
	color: #797a7f !important;
}

.f-color-dark-black-s80 {
	fill: currentColor;
	color: #4C4E54 !important;
}

.f-color-dark-black-s100 {
	fill: currentColor;
	color: #1F2229 !important;
}

.f-color-blue-s100 {
	fill: currentColor;
	color: #1CA8DD !important;
}

.f-color-white {
	fill: currentColor;
	color: #ffffff !important;
}

.f-color-securiti-green {
	fill: currentColor;
	color: #86B62C !important;
}

.f-color-risk-very-high {
	color: #D96758 !important;
}

.f-color-gray-s100 {
	color: #5F767C !important;
}