// variables
@gray-s10: #cfd6d8;
@form-bg: #f4f6f8;
@dark-black-s10: #e9e9ea;

// mixins
.f-size(@width; @height: @width) {
    width: @width;
    height: @height;
}

.position(@position; @top: null; @right: null; @bottom: null; @left: null) {
    position: @position;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

.font-size-line-height(@font-size; @line-height: @font-size) {
    font-size: @font-size;
    line-height: @line-height;
}

// Common styling for left border highlight
.f-left-border-highlight {
    min-height: 32px;
    padding: 4px 0 4px 10px;
    .f-text-medium-content;
    .f-color-dark-black-s80;
    box-shadow: inset 2px 0 0 #1ca8dd;
    .flex(flex, @align: flex-start, @justify: center, @direction: column);

    > *:first-child {
        font-size: inherit;
        line-height: inherit;

        &:not([class*='f-color-']) {
            // not using inherit because we still want to use the class f-color... to change the
            // border color but the same shouldnt be default set for the text
            .f-color-dark-black-s80;
        }
    }

    .f-secondary-text {
        .f-text-content;
        .f-color-dark-black-s50;
    }

    &:not([class*='justify-']) {
        justify-content: center;
    }

    &.f-small {
        .f-text-content;
        min-height: 26px;
    }

    &.darker-border {
        box-shadow: inset 3px 0 0 #1ca8dd;
    }

    // Using existing color classes to change the
    // border highlight label color
    // USAGE:
    // <div class="f-left-border-highlight f-color-success">...</div>
    &[class*='f-color-'] {
        box-shadow: inset 2px 0 0 currentColor;

        &.darker-border {
            box-shadow: inset 3px 0 0 currentColor;
        }
    }

    &.f-height-auto {
        height: auto;
        min-height: 0;
    }

    &.justify-space-between {
        justify-content: space-between;
    }

    > div {
        width: 100%;
    }
}

// Removes the border and extra spacing from the element.
.f-borderless-modal {
    .ant-modal-content {
        .ant-modal-confirm-btns,
        .ant-modal-confirm-title,
        .ant-modal-footer,
        .ant-modal-header {
            border: none;
        }

        .ant-modal-header,
        .ant-modal-confirm-title {
            padding-bottom: 0px;
        }
        .ant-modal-footer,
        .ant-modal-confirm-btns {
            padding-top: 0px;
        }
    }
}

.f-file-upload-container {
    z-index: 1;
    position: relative;
    text-align: center;
    padding: 24px;
    background-repeat: no-repeat;
    background-color: #f4f6f8;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-image: repeating-linear-gradient(
            0deg,
            #d2d3d4,
            #d2d3d4 16px,
            transparent 16px,
            transparent 32px,
            #d2d3d4 32px
        ),
        repeating-linear-gradient(90deg, #d2d3d4, #d2d3d4 16px, transparent 16px, transparent 32px, #d2d3d4 32px),
        repeating-linear-gradient(180deg, #d2d3d4, #d2d3d4 16px, transparent 16px, transparent 32px, #d2d3d4 32px),
        repeating-linear-gradient(270deg, #d2d3d4, #d2d3d4 16px, transparent 16px, transparent 32px, #d2d3d4 32px);

    input[type='file'] {
        opacity: 0;
        z-index: 5;
        .f-size(100%);
        .position(absolute, 0, 0);
    }

    .f-container-title {
        word-break: break-word;
        .f-color-dark-black-s80;
        .font-size-line-height(16px , 24px);

        &-failed {
            .f-color-risk-very-high !important;
        }
    }

    .f-container-subtitle {
        .f-color-dark-black-s50;
        .font-size-line-height(12px , 16px);
    }

    .f-file-name {
        text-align: left;
        // @include truncate;
        .f-color-dark-black-s80;
        // @include font-size-line-height(font-size(content), line-height(content));
    }

    .f-container-actions {
        z-index: 4;
        position: relative;
    }

    .ant-btn-text {
        .f-color-blue-s100;
        text-decoration: underline;
        font-weight: 450;
    }
}

// @include position(absolute, $top: 2px, $right: 5px)
.position(@position, @top: null, @right: null, @bottom: null, @left: null) {
    position: @position;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

// margin-top: grid-size(2); // output = 8px
// height: grid-size(5); // output = 40px

// Custom Component : Bulk Action Bar
// Used to apply bulk actions on selected items
// See it in action UT: PRIV-____
// USAGE:
// <v-card class="f-card f-bulk-action-bar">
//     ...
// </v-card>
.f-bulk-action-bar {
    &.f-card {
        width: 708px;
        max-width: 708px;
        z-index: 151;
        transform: translateX(-50%) !important;
        border-radius: 2px !important;
        box-shadow: 1px 1px 8px 0 @dark-black-s10 !important;
        border: 1px solid @gray-s10;
        background-color: @form-bg;
        .position(fixed, @left: 50%, @bottom: 88px);

        @media (max-width: @screen-lg) {
            width: 90%;
        }
    }
}

.notification {
    &-success {
        width: 382px;
        color: #70b246;
        padding: 8px 16px !important;
        background-color: #f7fbf4 !important;

        .ant-notification-notice-message {
            margin-bottom: 0 !important;
        }
    }

    &-message {
        color: #70b246;
        font-weight: bold;
    }

    &-description {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}