// Circular Pro
@font-face {
    font-family: 'LLCirc';
    src: url('../../fonts/circular-pro/lineto-circular-pro-book.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/circular-pro/lineto-circular-pro-book.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/circular-pro/lineto-circular-pro-book.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 100;
}

@font-face {
    font-family: 'LLCirc';
    src: url('../../fonts/circular-pro/lineto-circular-pro-medium.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/circular-pro/lineto-circular-pro-medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/circular-pro/lineto-circular-pro-medium.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: normal;
}

@font-face {
    font-family: 'LLCirc';
    src: url('../../fonts/circular-pro/lineto-circular-pro-bold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/circular-pro/lineto-circular-pro-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/circular-pro/lineto-circular-pro-bold.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 600;
}

// ITC Avant Garde Gothic Std
@font-face {
    font-family: 'ITCAvantG';
    src: url('../../fonts/avant-garde/ITCAvantGardeStd-Bk.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/avant-garde/ITCAvantGardeStd-Bk.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/avant-garde/ITCAvantGardeStd-Bk.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 100;
}