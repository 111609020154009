.settings-page {
    .ant-menu {
        &-item-group-list {
            .ant-menu-item {
                .f-color-dark-black-s80;
                margin: 0 16px;
                padding: 0 8px;
                width: calc(100% - 32px);
                padding-left: 8px !important;

                &:hover {
                    .f-color-blue-s100;
                    background-color: #F3FAFD;
                }

                &-selected {
                    .f-color-blue-s100;
                    background-color: #F3FAFD;

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}

.f-high-contrast {
    .settings-page {
        .ant-menu {
            &-item-group-list {
                .ant-menu-item {

                    &:hover {
                        .f-color-dark-black-s100;
                        border-color: @dark-black-s50;
                        background-color: @dark-black-s5 !important;
                    }

                    &-selected {
                        .f-color-dark-black-s100;
                        border-color: @dark-black-s50;
                        background-color: @dark-black-s5 !important;
                    }
                }
            }
        }
    }
}