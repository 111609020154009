// .position(absolute, @top: 2px, @right: 5px)
.position(@position, @top: null, @right: null, @bottom: null, @left: null) {
    position: @position;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

// .size(12px)
// OR
// .size(12px, 14px)
.size(@width, @height: @width) {
    width: @width;
    height: @height;
}

// .flex(flex, @direction: column, @justify: center, @align: center)
.flex(@display, @direction: null, @justify: null, @align: null) {
    display: @display;
    flex-direction: @direction;
    justify-content: @justify;
    align-items: @align;
}