@import './font-mixins.less';

.f-text-big-heading {
    font-size: 72px !important;
    line-height: 88px !important;
    font-family: @title-family;
}

.f-text-heading {
    font-size: 48px !important;
    line-height: 64px !important;
    font-family: @title-family;
}

.f-text-mini-heading {
    font-size: 40px !important;
    line-height: 56px !important;
    font-family: @title-family;
}

.f-text-big-title {
    font-size: 32px !important;
    line-height: 48px !important;
    font-family: @title-family;
}

.f-text-title {
    font-size: 24px !important;
    line-height: 40px !important;
    font-family: @title-family;
}

.f-text-big-content {
    font-size: 16px !important;
    line-height: 24px !important;

    &.f-text-single-line {
        line-height: 16px !important;
    }
}

.f-text-medium-content {
    font-size: 14px !important;
    line-height: 24px !important;

    &.f-text-single-line {
        line-height: 16px !important;
    }
}

.f-text-content {
    font-size: 12px !important;
    line-height: 16px !important;
}

.f-text-small-content {
    font-size: 10px !important;
    line-height: 16px !important;
}

.f-text-capitalize {
    text-transform: capitalize;
}